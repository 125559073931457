import { defineStore } from "pinia";
import { useMainStore } from '@/store/main';
import { ProfileType } from '@/types/profile';
import { API_URL } from "@/utils/urls";

const MAIN_STORE = useMainStore()


export const useInstallationStore = defineStore("installationStore", {
  state: () => ({
    profile_displaying: null as ProfileType | null,
    siloProfiles: null as ProfileType[] | null,
  }),
  actions: {
    async setProfile(data: any): Promise<void> {
      const request_info = {
        "sensor_pk": parseInt(data.sensor_pk),
        "start_date": data.start_date,
        "end_date": data.end_date,
      }
      this.siloProfiles = await MAIN_STORE.postOnAPI(`${API_URL}/silo_profiles/silo_profile_history/`, request_info)
      this.profile_displaying = this.siloProfiles != null ? this.siloProfiles[this.siloProfiles.length - 1] : null
    },
    async setProfileToDisplay(data: any): Promise<void> {
      this.profile_displaying = data
    },
    async validateProfile(profile: ProfileType): Promise<void> {
      const user_pk = MAIN_STORE.checkUserPK()
      await MAIN_STORE.postOnAPI(`http://18.229.162.27:4242/register_profile/?pk_user=${parseInt(user_pk)}&pk_acquisition=${profile.acquisition}&pk_silo_profile=${profile.id}&profile_class=valid_profile/`, {})
    },
    async invalidateProfile(profile: ProfileType): Promise<void> {
      const user_pk = MAIN_STORE.checkUserPK()
      await MAIN_STORE.postOnAPI(`http://18.229.162.27:4242/register_profile/?pk_user=${parseInt(user_pk)}&pk_acquisition=${profile.acquisition}&pk_silo_profile=${profile.id}&profile_class=invalid_profile/`, {})
    },
    async deleteWeight(profile: ProfileType): Promise<void> {
      await MAIN_STORE.deleteFromAPI(`${API_URL}/silo_feed_weights/${profile.silo_feed_weight}`)
    },
    async updateProfile(profile_to_update: ProfileType | null): Promise<void> {
      if (profile_to_update == null) return;
      this.siloProfiles?.map(profile => {
        if (profile_to_update.id === profile.id) {
          profile.level = null
          profile.silo_feed_weight = null
        }
      })
    },
  },
  getters: {
    getProfiles: state => state.siloProfiles,
    getProfileToDisplay: state => state.profile_displaying,
  },
});
